import "./App.css";
import Molstar from "molstar-react";

function App() {
    //from pathname or use a hardcoded default for now
    //the url pathname contains a unique id to retrieve the file
    //from a Google bucket.
    //TODO: API call to retrieve a local url such as bantha.tundra.bio/visualization/<RANDOM_ID>.pdb
    //or an external url (need to allow CORS)
    const url = new URL(new URLSearchParams(window.location.search).get("structure-url"))?.href
    // eslint-disable-next-line no-console
    console.log(url)
    const urlStructureFormat = new URLSearchParams(window.location.search).get("structure-url-format") || "pdb"

    if (url) {
        // eslint-disable-next-line no-console
        console.log("loading url: ", url, urlStructureFormat);
        return <Molstar url = { url }
        urlStructureFormat = { urlStructureFormat }
        />;
    }
    const uniqueId = window.location.pathname.substring(1).split(".")[0] || "9ins";

    //for now, we're using the pdbId prop which is 
    //translated in Molstar to fetch the file from RCSB
    return <Molstar pdbId = { uniqueId }
    />
}

export default App;
